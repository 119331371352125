#page .rte.pollsurvey_formBox {
	padding-top: 20px;
	background: 0 0;
	border: 1px solid #d6d4d4;
}
#pollsurvey_contentBox,
#pollsurvey_formBox .post .post-infos .meta .title {
	margin-bottom: 0;
}

.popin-button {
	margin-bottom: 60px;
}

.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 50%;
	max-width: 630px;
	min-width: 250px;
	height: auto;
	z-index: 20000;
	visibility: hidden;
	background: #18487B;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	border: 10px solid #083F79;
}

.show {
	visibility: visible;
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	visibility: hidden;
	top: 0;
	left: 0;
	z-index: 10000;
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.show ~ .overlay {
	opacity: 1;
	visibility: visible;
}

.popin-content {
	color: #fff;
	position: relative;
	border-radius: 3px;
	margin: 0 auto;
	padding: 6% 10%;
}

.popin-content h3 {
	margin: 0;
	text-align: center;
	font-size: 3.4em;
	font-weight: 300;
	position: relative;
}

.popin-content h3:before {
	position: absolute;
	width: 47px;
	height: 38px;
	content: "";
	top: 18px;
	left: 0px;
}

.popin-content .para {
	color: #73556b;
	margin-bottom: 30px;
	text-align: justify;
}

.popin-content > div {
	padding: 0px;
	margin: 0;
	font-weight: 300;
	font-size: 1.15em;
}

.popin-content > div p {
	margin: 0;
	padding: 10px 0;
}

.popin-content > div ul {
	margin: 0;
	padding: 0 0 30px 20px;
}

.popin-content > div ul li {
	padding: 5px 0;
}

.popin-content button {
	display: block;
	margin: 0 auto;
	font-size: 0.8em;
}

.close {
	background: url(../img/close.png) no-repeat;
	height: 49px;
	width: 49px;
	position: absolute;
	right: -35px;
	top: -35px;
	z-index: 9999;
	cursor: pointer;
	opacity: 1;
	-webkit-transition: all ease-in-out 0.3s;
	-o-transition: all ease-in-out 0.3s;
	transition: all ease-in-out 0.3s;
}

.close:hover {
	opacity: 0.2;
}

.submit {
	color: white;
	font-family: 'Lato', sans-serif;
	font-size: 18px;
	opacity: 1;
	cursor: pointer;
	-webkit-transition: all ease-in-out 0.3s;
	-o-transition: all ease-in-out 0.3s;
	transition: all ease-in-out 0.3s;
}

.submit:hover {
	opacity: 0.5;
}


/* Effet blur */
.show.blur-effect ~ .overlay {
	background: rgba(53, 87, 123, 0.4);
}

.show.blur-effect ~ #page {
	-webkit-filter: blur(5px);
	-moz-filter: blur(5px);
	filter: blur(5px);
}

.blur-effect .popin-content {
	-webkit-transform: translateY(-5%);
	-moz-transform: translateY(-5%);
	-ms-transform: translateY(-5%);
	transform: translateY(-5%);
	opacity: 0;
}

.show.blur-effect ~ #page,
.blur-effect .popin-content {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.show.blur-effect .popin-content {
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}

/* Media queries */

@media(max-width: 1230px) {

	.popin-content h3:before {
		display: none;
	}

}

@media(max-width: 680px) {

	h1 {
		font-size: 42px;
	}

}

@media(max-width: 480px) {

	h1 {
		font-size: 32px;
	}

	.popin-content h3 {
		font-size: 2em;
	}

	.close {
		right: 1px;
		top: 1px;
	}

}


/*
| footer-bar Template
|--------------------------------------------------------------------------
*/
.footer-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: auto;
	background: #eeeeee;
	border-top: 1px solid #dddddd;
	z-index: 10000;
	display: none;
}









/*
| Form PollSurvey
|--------------------------------------------------------------------------
*/
.pollsurvey_form {
	font-family: 'Roboto Condensed', sans-serif;
}
.pollsurvey_form label.form-control-label {
	font-size: 1.7rem;
}
.pollsurvey_form label {
	font-size: 1.5rem;
	color: #777777;
}
.pollsurvey_form label.required:before {
	content: none;
}
.pollsurvey_form label.required:after {
	content: "*";
	color: red;
	font-size: 14px;
	position: relative;
	line-height: 12px;
}
.pollsurvey_form .text-muted {
	font-size: 1.3rem;
	display: block;
	border-bottom: 1px dotted #ddd;
	margin-left: 15px;
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 15px;
	padding-bottom: 10px;
}
.pollsurvey_form .form-control-container {
	margin-bottom: 15px;
}
.pollsurvey_form .panel-footer {
	margin-left: -20px;
	margin-bottom: -20px;
	margin-right: -20px;
}
.pollsurvey_form .form-control-container .form-control-radio {
	/*display: block;
	margin-bottom: 5px;*/
}
.pollsurvey_form .form-control-container textarea {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid #e5e5e5;
	color: #999999;
}
.pollsurvey_form .form-control-container input {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid #e5e5e5;
	color: #999999;
}
.pollsurvey_form .form-control-other {
	display: none;
}
.pollsurvey_form .form-control-container .checkbox-inline:first-child {
	padding-left: 0;
}
.pollsurvey_form div.checker {
	display: inline-block;
}
.pollsurvey_form .icon-yes {
	background: url(../img/yes.png) no-repeat;
	display: inline-block;
	vertical-align: middle;
	height: 32px;
	width: 32px;
}
.pollsurvey_form .icon-no {
	background: url(../img/no.png) no-repeat;
	display: inline-block;
	vertical-align: middle;
	height: 32px;
	width: 32px;
}
.pollsurvey_form .has-error .form-control {
	border-color: #a94442;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}
.pollsurvey_form label.has-error,
.pollsurvey_form label.has-error {
	color: #a94442;
}
.pollsurvey_form button {

}
.pollsurvey_form .cofu {
	border: 1px dashed #bdbdbd;
	padding: 8px;
	margin-top: 40px;
	margin-bottom: 10px;
	background: white;
}
#left_column .pollsurvey_form .cofu,
#right_column .pollsurvey_form .cofu {
	display:none;
}
#left_column .pollsurvey_form .col-sm-12,
#right_column .pollsurvey_form .col-sm-12 {
	padding-left: 10px;
}
#left_column .pollsurvey_form,
#right_column .pollsurvey_form {
	min-height: 20px;
	padding: 15px 0;
	margin-bottom: 20px;
	background:none;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	border-top: none;
	margin-top: -20px;
}
#left_column .pollsurvey_form .panel-footer,
#right_column .pollsurvey_form .panel-footer {
	margin-left: 0px;
	margin-bottom: -15px;
	margin-right: 0px;
}
#left_column .well,
#right_column .well {
	background: none;
	border: none;
	box-shadow: none;
	margin-bottom: 0;
}
#module-pollsurvey-reply .pollsurvey_form .panel-footer {
	margin-left: 0px;
	margin-bottom: 0px;
	margin-right: 0px;
}
.pollsurvey_cms {
	/*padding: 20px;*/
}
a.button span.white {
	color: #ffffff;
}

#module-pollsurvey-preview #page .rte.pollsurvey_formBox {
	padding: 0 20px 20px;
}
#module-pollsurvey-preview #page #header,
#module-pollsurvey-preview #page #footer {
	display: none;
}

.cartcode {
	display: block;
	width: 200px;
	padding: 15px;
	border: 3px dashed #666666;
	margin: 20px auto;
	text-align: center;
	background: #eeeeee;
	color: #000000;
	text-shadow: none;
	font-size: 20px;
	white-space: nowrap;
}
label.small {
	font-weight: normal;
}
#cms h1 small,
#cms .h1 small {
	font-size: 2rem;
	float: right;
}

#left_column form div[id^="wrapper_quest_"],
#right_column form div[id^="wrapper_quest_"] {

}
#left_column form div[id^="wrapper_quest_"]:not(:first-child) ,
#right_column form div[id^="wrapper_quest_"]:not(:first-child) {
	display: none;
}

#left_column .alert ul,
#right_column .alert ol {
	padding-left: 0;
	margin-left: 0;
}

#left_column .alert ul li,
#right_column .alert ol li {
	list-style: none;
}

#left_column .page-heading,
#right_column .page-heading {
	margin-bottom: 0;
	margin-top: 0;
	padding-bottom: 5px;
}

/*
|--------------------------------------------------------------------------
| Progress bar
|--------------------------------------------------------------------------
*/
#left_column .progress,
#right_column .progress {
	width: 100%;
	height: 25px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	overflow: hidden;

}
#left_column .progress-wrap,
#right_column .progress-wrap {
	background: #137290;
	border: 1px solid #eeeeee;
	margin: 5px 0 10px 0;
	overflow: hidden;
	position: relative;
}

#left_column .progress-wrap .progress-bar,
#right_column .progress-wrap .progress-bar {
	background: #88C8FF;
	position: absolute;
	left: 0;
	top: 0;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	overflow: hidden;
}

#left_column .progress-wrap span,
#right_column .progress-wrap span {
	padding: 4px;
	color: #fff;
	position: absolute;
}


/*
|--------------------------------------------------------------------------
| Settings
|--------------------------------------------------------------------------
*/

#pollsurvey_settings h3 {
	font-size: 16px;
	border-bottom: none;
	margin: 0;
	padding: 0 0 17px 0;
}

#pollsurvey_settings #pollsurvey_settings_form {
	margin-left: 20px;
	margin-top: 20px;
}